import * as React from "react"
import { Link } from "gatsby"

const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

const NotFoundPage = () => {
  return (
    <div class="d-flex align-items-center justify-content-center vh-100">
      <div class="text-center row">
        <div class=" col-md-6">
          <img loading={"lazy"} src="https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg" alt=""
            class="img-fluid" />
        </div>
        <div class=" col-md-6 mt-5"> 
          <p class="fs-3"> <span class="text-danger">Opps!</span> Page not found.</p>
          <p class="lead">
            The page you’re looking for doesn’t exist.
          </p>
          <Link to="/" class="btn btn-primary">Go Home</Link>
        </div>

      </div>
    </div>
  )
}

export default NotFoundPage

export const Head = () => <title>Not found</title>
